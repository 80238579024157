import React from 'react'
import lary from '../LAR_logo_1__1_-removebg-preview.png'

const lar = () => {
  return (
    <div className="relative container md:w-[800px] h-[370px] md:h-[430px] w-[320px] bg-con rounded-3xl">  
    <div className='md:mt-8 md:ml-8 p-4 font-montserrat'>
          <h1 className='font-black pt-3 md:text-7xl text-3xl text-gold-normal'>LAR</h1>
          </div>
       <div className='absolute w-[320px] left-1/2 top-[35px] transform -translate-x-1/2  flex justify-center items-center md:w-[400px] '>
            <img src={lary} className="w-full md:opacity-50 opacity-30" />
          </div>
          <div className='container pt-2 text-[12px] md:w-[665px] h-[315px] pl-4 md:pl-4 md:text-lg md:ml-8 font-normal text-luna leading-[25px] md:tracking-wide'>
       <p>
            As the web3 game evoved, so did society and all will be thanks to a simple 
            tap on a screen with the giant leap humanity take towards prosperity.</p>
            <p className='pt-3'>
            Lunar hoping to and will surely take you all on a journey never thought 
             possible, but some wouldn't wait to see what other secrets the moon held.
            </p>
          </div>
        </div>
  )
}

export default lar