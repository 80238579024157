import React from 'react';
import Slider from "react-slick";
import Slt from './slidecon/slt';
import Welcome from './slidecon/welcome';
import About from './slidecon/about';
import Alunar from './slidecon/alunar';
import Lar from './slidecon/lar';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Sling = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 5000, // Set autoplay speed to 3 seconds
    customPaging: () => (
      <div
        style={{
          width: "10px",
          height: "10px",
          backgroundColor: "white",
          borderRadius: "50%",
          display: "inline-block",
          margin: "0 5px"
        }}
      />
    ),
    appendDots: dots => (
      <div
        style={{
          backgroundColor: "#000",
          borderRadius: "10px",
          padding: "10px"
        }}
      >
        <ul style={{ margin: "0px", display: 'flex', justifyContent: 'center' }}> {dots} </ul>
      </div>
    )
  };

  return (
    <div>
      <h1 className='text-white md:text-[65px] font-montserrat font-black mt-10 mb-6 text-center
      text-3xl'>Who We Are</h1>
      <Slider {...settings}>
        <div>
          <div className='justify-center flex items-center'>
            <Welcome />
          </div>
        </div>
        <div>
          <div className='justify-center flex items-center'>
            <About />
          </div>
        </div>
        <div>
          <div className='justify-center flex items-center'>
            <Alunar />
          </div>
        </div>
        <div>
          <div className='justify-center flex items-center'>
            <Slt />
          </div>
        </div>
        <div>
          <div className='justify-center flex items-center'>
            <Lar />
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default Sling;
