import React from 'react'
import road from './IMG_20240722_102330_028 1.png'

const Road = () => {
  return (
    <>
    <body className='flex mt-7 mb-16 flex-col items-center'>
        <div className='mt-9 '>
        <h1 className='text-white md:mt-20 md:text-[60px] text-3xl font-montserrat font-black mt-5 md:mb-10 mb-3'>Road Map</h1>
        </div>
        <div>
            <img src={road} alt="road map" width="100%"/>
        </div>
    </body>
    </>
  )
}

export default Road