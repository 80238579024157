import React from 'react';
import Iphone from '../DPhone.png';
import LogoGallery from './logogallery'; // Corrected import

const Main = () => {
  return (
    <div className="text-center pt-3 text-foreground md:pb-10 md:pr-10 md:pl-10">
      <main className="flex flex-col items-center">
        <h1 className="text-white md:text-[65px] leading-none font-montserrat md:font-black md:mb-5 mb-2 text-xl font-bold">
          Introducing Lunar Mining App
        </h1>
        <p className="md:text-2xl font-montserrat font-medium text-headertext md:mb-10 text-[9px] leading-1 mb-5">
          Lunar is a playful, interactive tap-to-earn game that combines
          <br /> The excitement of gaming with the potential <br />
          <span className="text-golden-moon">To earn real digital assets.</span>
        </p>

        <div className="md:mt-10 flex justify-center mt-0">
          <img
            src={Iphone}
            alt="Lunar app screenshot"
            className="relative md:w-[530px] md:h-[915.5px] w-[223px] h-auto" // Changed to h-auto
          />
        </div>

        <div className="md:mt-20 mt-5">
          <h2 className="text-white md:text-6xl md:font-black md:mb-4 mb-2 text-lg font-montserrat font-bold">
            Play Until You Capture The Moon
          </h2>
          <p className="text-maintext font-montserrat font-medium md:text-2xl md:mb-8 text-[9px] mb-3">
            Dive headfirst into the lunar web3 gaming arena, where <br />
            thrilling challenges, epic missions, and adventurous <br />
            quests await to help you amass untold riches!
          </p>
          <LogoGallery /> 
        </div>

        <div className="md:mt-10 mt-2 md:h-[103px] h-[70px] flex justify-center rounded-xl p-4">
          <a href="https://t.me/Thelunaroin_bot?start=ref_743737380" target="_blank" rel="noopener noreferrer">
            <button className="bg-gold-gradient md:w-[440px] md:h-[60px] font-montserrat md:font-bold font-medium text-sm md:text-lg tracking-wider text-white py-2 px-4 rounded-lg">
              Launch Lunar On Telegram
            </button>
          </a>
        </div>
      </main>
    </div>
  );
};

export default Main;
