import React from 'react'
import telegram from '../telegram.png'

const alunar = () => {
  return (
    <div className="relative container md:w-[800px] h-[370px] md:h-[430px] w-[320px] bg-con rounded-3xl">  
    <div className='md:mt-8 md:ml-8 p-4 font-montserrat'>
      <h1 className='font-medium text-white md:text-5xl tracking-norm text-2xl'>About</h1>
      <h1 className='font-black md:text-7xl text-3xl text-gold-normal'>Lunar</h1>
    </div>
    <div className='absolute bottom-[140px] md:bottom-[180px] left-1/2 transform -translate-x-1/2 flex justify-center items-center w-[610px] h-[70px]'>
      <img src={telegram} className='md:w-full w-[420px]  opacity-20' />
    </div>
    <div className='container text-[11px] md:w-[655px] h-[315px] pl-4 md:pl-4 md:text-lg md:ml-8 font-normal text-luna leading-[21px] md:tracking-wide'>
    <p>
      Lunar, an innovative tap-to-earn game avaliable on telegram 
      where you experience the thrill of space exploration and 
      base-building right from your mobile device.</p>
      <p className='pt-3'> Lunar combines the excitement of interactive gaming with <br/>
      the convenience of telegram where your taps turn into  digital treasures!</p>
      <p className='pt-3'>
      The more you tapped, the more resources you collect on the moon.
      </p>
    </div>
  </div>
  )
}

export default alunar