import React from 'react';
import { bin, bingx, crypto, bybit, kucoin, okx, mexc, las } from '../images/index';

const images = [
  { src: bin, alt: 'BNB logo' },
  { src: bingx, alt: 'ETH logo' },
  { src: crypto, alt: 'SOL logo' },
  { src: bybit, alt: 'AVAX logo' },
  { src: kucoin, alt: 'DOT logo' },
  { src: las, alt: 'DOT logo' },
  { src: mexc, alt: 'DOT logo' },
  { src: okx, alt: 'DOT logo' }
];

const logoGallery = () => {
  return (
    <div className="flex space-x-[-9px] justify-center md:mb-10 mb-2">
      {images.map((image, index) => (
        <img
          key={index}
          src={image.src}
          alt={image.alt}
          className="rounded-full md:w-[65px] md:h-14 w-9 h-9"
        />
      ))}
    </div>
  );
};

export default logoGallery;
