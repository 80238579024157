import React, { useState } from 'react';
import logo from '../logo.png';
import { ReactComponent as TelegramIcon } from '../svg/telegram.svg';
import { ReactComponent as TiktokIcon } from '../svg/tiktok.svg';
import { ReactComponent as TwitterIcon } from '../svg/twitter.svg';
import { ReactComponent as YoutubeIcon } from '../svg/youtube.svg';


const Header = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <div className="text-center text-foreground md:p-10 md:pt-9 pt-6">
        <header className="text-white md:ml-10 md:mr-10 md:pr-10 flex items-center justify-between md:mb-3 pb-5">
          <div className="flex items-center space-x-2 pl-5">
            <img
              src={logo}
              alt="Lunar logo"
              className="rounded-full md:w-[70px] md:h-[70px] w-12 h-12 gap-0"
            />
            <span className="md:text-xl text-sm font-montserrat font-black">Lunar</span>
          </div>
          <div className="flex space-x-4 items-center md:hidden pr-5">
            <button onClick={toggleSidebar} className="text-white">
              ☰
            </button>
          </div>
          <div className="hidden md:flex space-x-4 items-center">
            <a href="https://t.me/lunarcoincommunity" target="_blank" rel="noopener noreferrer">
              <TelegramIcon className="md:w-8 md:h-6 w-10 h-5 text-white" />
            </a>
            <a href="https://x.com/TheLunar_Coin?t=yYwG_FduEr29TTp6Y5j7_g&s=09" target="_blank" rel="noopener noreferrer">
              <TwitterIcon className="md:w-8 md:h-6 w-10 h-5 text-white" />
            </a>
            <a href="https://youtube.com/@thelunarcoinofficial?si=G9tylMfYBLrXC0-o " target="_blank" rel="noopener noreferrer">
              <YoutubeIcon className="md:w-8 md:h-6 w-10 h-5 text-white" />
            </a>
            <a href="" target="_blank" rel="noopener noreferrer">
              <TiktokIcon className="md:w-8 md:h-6 w-10 h-5 text-white" />
            </a>
          </div>
        </header>
      </div>

      {isSidebarOpen && (
        <div className="fixed top-0 bottom-0 right-0 w-[200px] h-[250px] bg-black text-white p-6 overflow-y-auto z-50">
          <button onClick={toggleSidebar} className="text-white mb-4">
            ✕
          </button>
          <div className="space-y-4">
            <div className="flex items-center space-x-3">
              <a href="https://t.me/lunarcoincommunity" target="_blank" rel="noopener noreferrer" className="block">
                <TelegramIcon className="md:w-8 md:h-6 w-10 h-5 text-white" />
              </a>
              <span>Telegram</span>
            </div>
            <div className="flex items-center space-x-3">
              <a href="https://twitter.com/yourTwitterProfile" target="_blank" rel="noopener noreferrer" className="block">
                <TwitterIcon className="md:w-8 md:h-6 w-10 h-5 text-white" />
              </a>
              <span>Twitter</span>
            </div>
            <div className="flex items-center space-x-3">
              <a href="https://www.youtube.com/yourYoutubeChannel" target="_blank" rel="noopener noreferrer" className="block">
                <YoutubeIcon className="md:w-8 md:h-6 w-10 h-5 text-white" />
              </a>
              <span>Youtube</span>
            </div>
            <div className="flex items-center space-x-3">
              <a href="https://www.tiktok.com/@yourTiktokProfile" target="_blank" rel="noopener noreferrer" className="block">
                <TiktokIcon className="md:w-8 md:h-6 w-10 h-5 text-white" />
              </a>
              <span>Tiktok</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
