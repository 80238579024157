import React from 'react'
import phone from '../phone.png'

const about = () => {
  
    return (
      <div className="relative container md:w-[800px] md:h-[430px] h-[370px] w-[320px] bg-con rounded-3xl"> 
       <div className='md:mt-8 md:ml-8 p-4 font-montserrat'>
       <h1 className='font-medium text-white md:text-5xl tracking-norm text-2xl'>About</h1>
       <h1 className='font-black md:text-7xl text-3xl text-gold-normal'>Lunar</h1>
    </div>
    <div className='absolute top-[50px] md:top-[150px]  right-0 flex md:pr-7 justify-center items-center z-0 md:w-[325px] md:h-[200px]'>
      <img src={phone} className='h-[300px] md:w-[250px] md:h-[360px] md:opacity-55 opacity-35' />
    </div>
    <div className=' text-[11px] container md:w-[555px] h-[315px] pl-4 md:pl-4 md:text-lg md:ml-8 font-normal text-luna leading-[25px] md:tracking-wide z-10'>
      <p>
        Lunar is a playful, interactive tap-to-earn game that combines <br/>
        the excitement of gaming with the potential 
        to earn real digital assets. </p>
        <p className='pt-3'> 
        Players from around the globe compete in a virtual world to mine, 
        trade, and collect lunar, striving to become the ultimate digital 
        tycoon.
      </p>
    </div>
  </div>
  )
}
export default about