import React, { useState } from 'react';
import logo from '../logo.png';
import { ReactComponent as TelegramIcon } from '../svg/telegram.svg';
import { ReactComponent as TiktokIcon } from '../svg/tiktok.svg';
import { ReactComponent as TwitterIcon } from '../svg/twitter.svg';
import { ReactComponent as YoutubeIcon } from '../svg/youtube.svg';



const Footer = () => {
  return (
   <>
   <div className='pb-5 md:pb-10'>
      <div className="text-center pt-6 text-foreground md:pl-10 md:pr-10 md:pt-9">  
      <footer className="text-white md:ml-10 md:mr-10 md:pr-10 flex items-center justify-between pb-2">
          <div className="flex items-center space-x-2 pl-5">
            <img 
              src={logo} 
              alt="Lunar logo" 
              className="rounded-full md:w-[70px] md:h-[70px] w-12 h-12 gap-0" 
            />
            <span className="md:text-xl text-sm font-montserrat font-black">Lunar</span>
          </div>
          <div className="flex md:space-x-4 space-x-1 pr-3 items-center ">
            
  <a href="https://t.me/lunarcoincommunity" target="_blank" rel="noopener noreferrer">
    <button>
      <TelegramIcon className="md:w-8 md:h-6 w-5 h-4 text-black" />
    </button>
  </a>
  <a href="https://x.com/TheLunar_Coin?t=yYwG_FduEr29TTp6Y5j7_g&s=09" target="_blank" rel="noopener noreferrer">
    <button>
      <TwitterIcon className="md:w-8 md:h-6 w-5 h-4 text-black" />
    </button>
  </a>
  <a href="https://youtube.com/@thelunarcoinofficial?si=G9tylMfYBLrXC0-o " target="_blank" rel="noopener noreferrer">
    <button>
      <YoutubeIcon className="md:w-8 md:h-6 w-5 h-4 text-black"/>
    </button>
  </a>
  <a href="" target="_blank" rel="noopener noreferrer">
    <button>
      <TiktokIcon className="md:w-8 md:h-6 w- h-4 text-black" />
    </button>
  </a>
 
</div>
</footer>
   </div>
    < div className=" md:ml-10  md:pl-12 md:text-xl text-xs pl-5 font-montserrat text-headertext  md:font-medium">
              <p>For more inquiries, please reach out to us.<br />
                For community requests, please reach out to us here.</p>
    </div>
    </div>
   </>
  );
};

export default Footer;

