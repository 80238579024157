import React from 'react'
import moon from '../../moonl.png'

const welcome = () => {
  return (
    <div className="relative container md:w-[800px] md:h-[430px] h-[370px] w-[320px] bg-con rounded-2xl">  
    <div className='md:mt-8 md:ml-8 p-4 font-montserrat'>
      <h1 className='font-medium text-white md:text-5xl tracking-norm text-2xl'>Welcome To</h1>
      <h1 className='font-black md:text-7xl text-3xl text-gold-normal'>Lunar</h1>
    </div>
    <div className='absolute md:top-[240px] top-[200px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center'>
      <div className='md:w-[325px] md:h-[300px] w-[340px]   bg-transparent flex justify-center items-center'>
        <img src={moon} className='w-full   opacity-20'/>
      </div>
    </div>
    <div className='container text-[11px] md:w-[650px] h-[315px] pl-4 md:pl-4 md:text-lg md:ml-8 font-normal text-luna leading-[21px] md:tracking-wide'>
      <p>In the not-so-distant future, humanity has set its sights on 
        the Moon, but this time it's not just about space 
        exploration it's about creating a thriving, self-sustaining lunar 
        colony.</p>
         <p className='pt-2'> As the world shifted towards a new era of technology, 
        a new form of entertainment emerged - web3 gaming.
        </p>
        <p className='pt-2 '> Among  the many new games was “Lunar” created which stood out with
        its unique concept of tap-to-earn.
      </p>
    </div>
  </div>
  )
}

export default welcome