import React from 'react'
import world from '../world.png'

const slt = () => {
  return (
    <div className="relative container md:w-[800px] h-[370px] md:h-[430px] w-[320px] bg-con rounded-3xl">  
   <div className='absolute top-[1px] w-[600px] left-1/2 bottom-[5px] transform -translate-x-1/2  flex justify-center items-center md:w-[720px] '>
         <img src={world} className="w-full opacity-20" />
       </div>
       <div className='container text-[11px] md:pt-10 md:w-[705px] h-[315px] pl-4 pt-7 md:pl-4 md:text-lg md:ml-8 font-normal text-luna leading-[22px] md:tracking-wide'>
       <p>
         Soon, players will be hooked, and "Lunar" will become 
         the most popular game in the world. But as the game keeps 
         on gaining popularity, we the creators face unexpected 
         challenges too but won’t be of much.</p>
         <p className='pt-3'>
         Lunar aims to provide an engaging and accessible gaming
         experience that allows players to explore the Moon by tapping 
         their ways through various challenges, missions, and quests directly 
         within Telegram and earn Lunar tokens (LRN).</p> 
         <p className='pt-3'> By leveraging on telegram's platform, Lunar ensures that everyone can join the
         adventure without needing specialized gaming equipment. 
         </p>
       </div>
     </div>
  )
}

export default slt