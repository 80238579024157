import React, { useState } from 'react';
import Header from './compoment/header';
import Footer from './compoment/footer';
import Main from './compoment/main';
import Section from './compoment/section';
import Slide from './compoment/sling';
import galaxy from './galaxy.png'
import Road from './compoment/road';

const App = () => {

  return (
    <>
      <body className="bg-black min-h-screen bg-cover ">
        <Header/>
        <Main/>
        <Slide/>
        <Road/>
        <Footer/>
      </body>
    </>
  );
};

export default App;